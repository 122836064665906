.img-hover-effect{
    border-radius: 1rem;
    overflow: hidden;
}

.img-hover-effect img{
    width: 100%; height: 100%;
    transition: scale  400ms;
}
.img-hover-effect:hover img {
    scale: 105%;
}

/* .Card-flow-hover {
    width: 400px;
}
.Card-flow-hover:hover {
    width: 500px;  
    z-index: 999; 
} */