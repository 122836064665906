@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}
/* *{
    overflow-x: hidden;
  } */
input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}


.popup-onhover {
	--c: #1095c1; /* the color */
	--b: .1em;    /* border length*/
	--d: 10px;    /* the cube depth */
	
	--_s: calc(var(--d) + var(--b));
	
	color: var(--c);
	border: solid #0000;
	border-width: var(--b) var(--b) var(--_s) var(--_s);
	background:
	  conic-gradient(at left var(--d)  bottom var(--d),
		#0000 90deg, rgb(255 255 255 /0.3) 0 225deg,rgb(255 255 255 /0.6) 0) border-box,
	  conic-gradient(at left var(--_s) bottom var(--_s),
		#0000 90deg,var(--c) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b))  border-box;
	transform: translate(calc(var(--d)/-1),var(--d));
	clip-path: 
	  polygon(
	   var(--d) 0%, 
	   var(--d) 0%, 
	   100% 0%, 
	   100% calc(100% - var(--d)),
	   100% calc(100% - var(--d)),
	   var(--d) calc(100% - var(--d))
	  );
	transition: 0.5s;
  }
  .popup-onhover:hover {
	transform: translate(0,0);
	clip-path: 
	  polygon(
	   0% var(--d), 
	   var(--d) 0%, 
	   100% 0%, 
	   100% calc(100% - var(--d)), 
	   calc(100% - var(--d)) 100%, 
	   0% 100%
	 );
  }
  #SearchBoox input {
	padding: 1.73rem;
	outline: none;
	border: 0;
  }
  .dark   #SearchBoox input {
color: white;
  }
  #SearchBoox input::placeholder {
	height: 3.5rem;
	line-height: 3.5rem;
	margin-left: 0.25rem;
	padding-left: 1rem;
	outline: none;
	color: navy;
	border: 0;
  }
  .dark #SearchBoox input::placeholder {
	color: white;
	border: 0;
  }
  #SearchBoox input:active input::after {
	height: 100%;
	outline: none;
	border: 0;
  }

::-webkit-scrollbar {
    width: 0px;
    background: transparent;

}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
    /* background: radial-gradient(81.25% 81.25% at 73.33% 16.67%, #CBA272 0%, #B5772F 88.54%, #B96C13 100%); */
}
.wishlist{
	background-image: url("./assets/img/landing/wishlist.jpg");
	background-size: cover;
	height: 60vh;
	font-family: 'Franklin Gothic ';
	text-align: center;
}
.wishlistbt{
	background-color: rgba(79, 79, 232, 0.922);
	color: white;
	border-radius: 10px;
	padding: 0.5% 1.5% 0.5%;
	font-size: 20px;
	margin-top: 2%;
}
h1{
	font-size: 32px;
	font-weight: bolder;
	
}
h2{
	font-size: 24px;
	font-weight: bolder;
}
h3{
	font-size: 18.72px;
	/* font-weight: bolder; */
}
h4{
	font-size: 16px;
	font-weight: bolder;
}
.supershow{
	background: linear-gradient(270.06deg, #FF820E 7.8%, #7926FF 52.51%, #356DF3 91.97%), #1F2226;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}