.card01 {
    background: rgb(21, 35, 85);
    border-radius: 55px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.9px);
    -webkit-backdrop-filter: blur(10.9px);
    width: 22vw;
    height: 45vh;
    margin: 1%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    color: white;


}
.card01:hover{

}
.h01 {
font-size: 30px;
margin: 1%;
}

.card02 {
    /* width: 50vw; */
    margin-left: 15%;
    padding: 9%;
}

.h012 {
    font-size: 160%;
}
.slider023{
    /* width:35vw; */
    /* position: absolute; */
    right: 6%;
    padding: 2%;
}
.slid012{
/* display: flex; */
/* flex-direction: row; */
}
/* @media  screen and (max-width:1025px) {
    .slid012{
   height: 62vh;
}
.slider023{
    width:40vw;
    
}

} */
@media  screen and (max-width:770px) {
   

} 