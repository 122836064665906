.slick-dots {
  position: absolute;
  bottom: 1% !important;
}
.slick-dots li button::before {
  color: rgb(23, 30, 85) !important;
}
.dark .slick-dots li button::before {
  color: rgb(255, 255, 255) !important;
}
.slider-bg-relative {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}
.slider-bg-relative img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  object-fit: cover;
  background-blend-mode: luminosity;
}

.slider-bg-relative video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.slider-bg-absolute {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 10%;
  top: 20%;
  z-index: 1;
}

.slider-single-bg-relative{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;

}
.slider-single-bg-absolute {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0%;
  bottom: 0%;
  white-space: nowrap;
  z-index: 1;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(29, 35, 46, 0.15);
  /* border-radius: 12px; */
  border: 1px solid rgba(255, 255, 255, 0.125);
  width: 100%;
}

button:hover {
  background-color: rgba(171, 179, 208);
  color: white;
}
.dark button:hover {
  background-color: rgba(171, 179, 208);
  color: white;
}
.social-icons {
  background-color: #0b1337;
  cursor: pointer;
  color: WHITE;
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.social-icons:hover {
  background-color: #fa05c1;
  /* border-radius:50%;  */
  box-shadow: outset, inset/h-offset v-offset blur spread color;
}
