.ul01{
    font-size: large;
    color: #6e6d7a;
    border-bottom:2px solid #6e6d7a;
}
.ul01 li{
    padding: 7px 0;
}
.input012{
    
    width: 40vw;
    border-width: 2px;
    border-color: #e7e7e9;
    background-color: #fff;
    border-radius: 10px;
}
.inputdiv{
    margin: 2%;

}
.backimg{
    background-image: url("../../../assets/img/settings/settings.jpg");
    width: 70vw;
    padding: 2%;
        border-radius: 10px;
        background-size: cover;
      
}
