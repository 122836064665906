/* #e84c3d: #e84c3d;
#ecedef: #ecedef;
#343434: #343434; */

.cardWrap {
  width: 30em;
  margin:auto;
  color: #fff;
  font-family: sans-serif;
  /* transform : rotate(90deg); */
}

.card {
  background: linear-gradient(to bottom, #e84c3d 0%, #e84c3d 26%, #ecedef 26%, #ecedef 100%);
  height: 15em;
  float: left;
  position: relative;
  padding: 1em;
  margin-top: 100px;
}

.cardLeft {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 20em;
}

.cardRight {
  width: 10em;
  border-left: .18em dashed #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: .9em;
    height: .9em;
    background: #fff;
    border-radius: 50%;
    left: -.5em;
  }
  &:before {
    top: -.4em;
  }
  &:after {
  bottom: -.4em;
  }
}


.title, .name, .seat, .time {
  text-transform: uppercase;
  font-weight: normal;
  h2 {
    font-size: .9em;
    color: #525252;
    margin: 0;
   }
  span {
    font-size: .7em;
    color: #a2aeae;
  }
}

.title {
  margin: 1em 0 0 0;
}

.name, .seat {
  margin: .5em 0 0 0;
}

.time {
  margin: .5em 0 0 1em;
}

.seat, .time {
  float: left;
}

.eye {
  position: relative;
  width: 2em;
  height: 1.5em;
  background: #fff;
  margin: 0 auto;
  border-radius: 1em/0.6em;
  z-index: 1;
  &:before, &:after {
    content:"";
    display: block;
    position: absolute;
    border-radius: 50%;
  }
  &:before {
    width: 1em;
    height: 1em;
    background: #e84c3d;
    z-index: 2;
    left: 8px;
    top: 4px;
  }
  &:after {
  width: .5em;
  height: .5em;
  background: #fff;
  z-index: 3;
  left: 12px;
  top: 8px;
  }
}

.number {
  text-align: center;
  text-transform: uppercase;

}

.number h3 {
  color: #e84c3d;
  margin: .9em 0 0 0;
  font-size: 2.5em;
}

.number span {
  display: block;
  color: #a2aeae;
}

.barcode {
  height: 2em;
  width: 0;
  margin: 1.2em 0 0 .8em;
  box-shadow: 1px 0 0 1px #343434,
  5px 0 0 1px #343434,
  10px 0 0 1px #343434,
  11px 0 0 1px #343434,
  15px 0 0 1px #343434,
  18px 0 0 1px #343434,
  22px 0 0 1px #343434,
  23px 0 0 1px #343434,
  26px 0 0 1px #343434,
  30px 0 0 1px #343434,
  35px 0 0 1px #343434,
  37px 0 0 1px #343434,
  41px 0 0 1px #343434,
  44px 0 0 1px #343434,
  47px 0 0 1px #343434,
  51px 0 0 1px #343434,
  56px 0 0 1px #343434,
  59px 0 0 1px #343434,
  64px 0 0 1px #343434,
  68px 0 0 1px #343434,
  72px 0 0 1px #343434,
  74px 0 0 1px #343434,
  77px 0 0 1px #343434,
  81px 0 0 1px #343434;
}