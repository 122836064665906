.newsletter-container {
    background-color: #007bff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    color: #fff;
  }
  
  .newsletter-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .newsletter-description {
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .newsletter-form {
    position: relative;
    overflow: hidden;
  }
  
  .newsletter-input {
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 16px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .newsletter-input::placeholder {
    color: #e6e6e6;
  }
  
  .newsletter-input input:focus {
    outline: none;
    border-color: #fff;
  }
  
  .newsletter-button {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
  .newsletter-button:hover {
    background-color: #fff;
    color: #007bff;
  }
  
  /* Animation */
  .newsletter-form:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(45deg, #fff, #007bff);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .newsletter-form:hover:before {
    opacity: 1;
  }
  
  .newsletter-form:hover .newsletter-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .newsletter-form:hover .newsletter-button:hover {
    background-color: #fff;
    color: #007bff;
  }
  



  .about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
  }
  
  .about-us-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    /* color: #333; */
  }

  
  .about-us-description {
    font-size: 16px;
    /* color: #666; */
    margin-bottom: 20px;
  }
  
  .organizers-title {
    font-size: 24px;
    font-weight: bold;
    /* color: #333; */
    margin-bottom: 20px;
  }
  
  .organizer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-in-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .organizer-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 20px;
    animation: zoomIn 1s ease-in-out;
  }
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .organizer-info {
    flex: 1;
  }
  
  .organizer-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .organizer-description {
    font-size: 16px;
    color: #666;
  }
  