.aboutus {
  font-family: "DM Sans", Sans-serif;
  /* background-color: white; */
}

.about01 {
  /* background-image: url("./ABOUT\ US.png"); */
  background-color: rgb(74, 74, 151);
  object-fit: cover;
  height: 70vh;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.h_1 {
  font-size: 100px;
  font-family: 'Montreux Jazz', sans-serif;
  font-weight: bold;
}

.h1 {
  font-size: 60px;
  font-family: 'Montreux Jazz', sans-serif;
  font-style: italic;
  /* -webkit-text-stroke: 2px black;
    color: transparent */
}

.about02 {
  margin-top: 5%;
  text-align: center;
}


.elementor-button-text {
  font-family: "DM Sans", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #0A84FF;
  border-radius: 25px 25px 25px 25px;
  padding: 10px 16px 10px 16px;
}

.about03 {
  display: grid;
  grid-template-columns: auto auto;
  background-color: white;
  overflow: hidden;

  /* margin:5%; */
  /* margin-bottom: 80px; */


}

.div02 {
  padding-left: 7%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.col01 {
  padding: 8%;
}

.div01 {
  padding: 10%;
  padding-top: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* .img01{
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
} */
.img02 {
  width: 11vw;

}

.row02 {
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 2%;
}

.elementor-size-default1 {
  margin-top: 2%;
  color: #616498;
  font-family: "DM Sans", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 3%;
}

.mas-elementor-nav-tab {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border: none;
  margin-left: -2%;

}

.nav-link.active {
  /* padding: 0px 0px 4px 0px; */
  /* border-style: solid; */
  /* border-width: 0px 0px 4px 0px; */
  /* border-color: #0A84FF; */
  border: none;
  color: #0C1E35;
  font-family: "DM Sans", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.nav-link {
  color: #617798;
  font-size: larger;

}

.nav-tabs .nav-link.active {
  border-bottom: 4px solid #0A84FF;
}

.about04 {
  display: grid;
  grid-template-columns: auto auto;
  padding: 3%;
  margin-top: -8%;
}

.about05 {
  font-family: sans-serif;
  text-align: center;

}

.question-section {
  background: transparent;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  width: 42vw;
  height: 10vh;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-align h4 {
  margin-left: 8px;
}

.question-styling {
  font-size: 17px;
  width: 100%;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
}

.answer-divider {
  border-top: 1px solid lightgray;
}

.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide1 {
  flex-shrink: 0;
  width: 30vw !important;

  /* height: 50vh; */
  position: relative;
  transition-property: transform;
  display: block;
  /* From https://css.glass */
  background: rgb(21, 33, 78);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.4px);
  -webkit-backdrop-filter: blur(10.4px);
}

.swiper-slide img {
  display: block;
  /* width: 50vw; */
  object-fit: cover;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

.about06 {
  /* display: grid;
  grid-template-columns: auto auto ; */

}

.hero-heading.tc-beige {
  max-width: 18ch;
  margin-bottom: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: 'Montreux Jazz', sans-serif;
  font-size: 48px;
  line-height: 57px;
  font-weight: 800;
}

.col-2.lg-9.tc-beige {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 18%;
}

.diamond {
  background: rgb(17 28 68 / var(--tw-bg-opacity));
  height: 60px;
  text-align: center;
  transform: rotate(45deg);
  width: 60px;

  display: table-cell;
  width: 20px;
  height: 20px;
  transform: rotate(-47deg);
  vertical-align: middle;
  margin-top: 24px;
  margin-bottom: 24px;
  /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWHyKADG0Nh6yqrA6w2Kxad8JZW7IJNIYZrMLDGx-ZAsSjSsQZLFivXAq_yL2jyZl1D7s&usqp=CAU"); */
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.dark .diamond {
  background: white;
}

.row-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.wrapper {
  display: block;
  padding: 5%;
  /* margin-top: -10%; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.col-2.lg-6 {
  width: 30%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.description-dedign {
  max-width: 33ch;
  font-family: 'Montreux Classic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.description {
  font-family: 'Montreux Classic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.row-2.timeline {
  /* margin-bottom: 160px; */
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.col-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.round-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 144px;
  height: 144px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid rgb(17 28 68 / var(--tw-bg-opacity));
  border-radius: 100px;
  text-align: center;
}

.round-button:hover {
  background-color: rgb(17 28 68 / var(--tw-bg-opacity));
  color: white;
}

.faq {
  border: 1px solid black;
  border-radius: 15px;
  margin: 1%;
  width: 60vw;
  /* height: 12vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;


}

.faq p {
  display: none;
}

.faq:hover p {
  display: block;

}

.faq012 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.open1 {}

.line1 {
  position: absolute;
  top: 274px;

}

@media screen and (max-width:1037px) {
  .line1 {
    top: 214px;
  }

  .h1 {
    font-size: 200%;
  }

  .h_1 {
    font-size: 200%;
  }

  .img01 {
    display: none;
  }
}

@media screen and (max-width:695px) {
  .faq {
    width: 85vw;
    /* height: 17vh; */
  }
  .faq012{
    margin-left: 5%;
  }

}

@media screen and (max-width:846px) {
  .swiper-slide1 {
    width: 70vw !important;
  }

  .open1 {
    display: none;
  }

  .h1 {
    margin-top: 10%;
  }

}

.org1 {
  background-image: linear-gradient(135deg, #232a42de 10%, #1d2571 100%);
}

.dark .org1 {
  background-image: linear-gradient(135deg, #0d1453 10%, #1c1731 100%);
}

.p01 {


  max-height: 102px;
  line-height: 1.5;
  color: rgb(37, 34, 34);
  font-weight: 400;
  letter-spacing: 0.18px;
  font-size: 17px;
}

.background {
  background: lightblue url("../../assets/img/landing/live-music-concert-stage-background\ \(1\).jpg") no-repeat center;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 90vh;


}

.transbox {
  background-color: rgba(31, 26, 45, 0.564);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 20px;
  margin: 30px;
  width: 75vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 30px;
  margin-top: 9%;
}

.card012 {
  background: rgba(22, 26, 126, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(5px);
  margin: 1%;
  text-align: center;
  opacity: 0.9;
}

.card012:hover {
  transform: scale(1.05);
  /* z-index: 999; */
  /* background-color: coral; */
  box-shadow: 5px 5px 5px rgba(44, 80, 151, 0.547);
}

.card0123 {
  transition: transform 330ms ease-in-out;
}

.card0123:hover {
  transform: scale(1.13);
  /* transition: transform 330ms ease-in-out; */
}
.pricing-one {
  position: relative;
  /* background-image: url('../images/background/pricing-background.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
}


.pricing-one__sep {
  width: 100%;
  height: 1px;
  background-color: #e8e8ef;
  display: block;
  margin-bottom: 120px;
}

.pricing-one__single {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #00000040;
  /* border-color: rgb(232, 232, 239); */
  /* background-color: rgb(255, 255, 255); */
  /* box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05); */
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.priceHead {
  padding: 60px;
  /* background-color: #f6f6f6; */
}

.pricing-default {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 15px #b9b9d7;
  margin-top: -5%;
  height: 106%;
}

.pricing-default .priceHead {
  background-color: rgb(37, 179, 84);
  color: #fff;
}

.pricing-default .thm-btn {
  background-color: #4259d0;
  border: 2px solid #4259d0;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.pricing-one__single:hover {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  /* border-color: #4259d0; */
  /* background-color: #4259d0; */
  /* color: #fff; */
  height: "";
  box-shadow: 0 0 15px #b9b9d7;
  margin-top: -5%;
  height: 106%;
}

.pricing-one__single:hover .priceHead {
  /* display: none; */
  transition: 0.5s ease-in-out;
  background-color: #4259d0;
  color: #fff;
}

.pricing-one__single:hover .thm-btn {
  background-color: #4259d0;
  border: 2px solid #4259d0;
  color: #fff;
  transition: all 0.5s ease-in-out;
}



.priceTag {
  color: "#2e2e74"
}

.priceHead:hover {
  background-color: #4259d0;
  color: #fff;
}

.pricing-one__inner {
  position: relative;
  /* background-color: #fff; */
  overflow: hidden;
  /* padding-top: 60px; */
  padding-bottom: 60px;
}

/* .pricing-one__inner:hover{
height: 1000px;
margin-top: -100;
} */

.pricing-one__icon {
  position: relative;
}

/* 
.pricing-one__single h4 {
margin: 0;
color: var(--thm-black);
font-size: 46px;
font-weight: 500;
position: relative;
line-height: 1em;
margin-top: 20px;
margin-bottom: 20px;
}

.pricing-one__single p {
margin: 0;
font-size: 18px;
font-weight: 500;
color: #7c7d8a;
position: relative;
line-height: 1em;
} */

.pricing-one__line {
  position: absolute;
  top: -145.5px;
  left: calc(50% - 147.5px);
}

.pricing-one__list {
  margin-bottom: 0;
  position: relative;
  border-top: 1px solid #e8e8ef;
  padding-bottom: 55px;
  padding-top: 55px;
  /* margin-top: 50px; */
}

.pricing-one__list li {
  line-height: 1em;
  font-size: 18px;
  font-weight: 500;
  color: #7c7d8a;
}

.pricing-one__list li i {
  color: green;
  font-size: 14px;
  margin-right: 5px;
}

.pricing-one__list li+li {
  margin-top: 20px;
}

/* .pricing-one__single span {
  display: block;
  text-transform: uppercase;
  line-height: 1em;
  color: #7c7d8a;
  font-weight: 500;
  position: relative;
  font-size: 12px;
  letter-spacing: .2em;
  margin-top: 20px;
} */

.pricing-one__btn {
  position: relative;
}

.pricing-one .block-title {
  margin-bottom: 30px;
}

.pricing-one .switch {
  position: relative;
  display: inline-block;
  width: 79px;
  height: 36px;
  vertical-align: middle;
  margin: 0;
}

.pricing-one .switch input {
  display: none;
}

.pricing-one .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
  background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 100%);
  background-color: #4259d0;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
}

.pricing-one .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 6px;
  bottom: 6px;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

.pricing-one input:focus+.slider {
  -webkit-box-shadow: 0 0 1px #2196F3;
  box-shadow: 0 0 1px #2196F3;
}

.pricing-one .switch.off .slider:before {
  -webkit-transform: translateX(42px);
  transform: translateX(42px);
}

.pricing-one .slider.round {
  border-radius: 34px;
}

.pricing-one .slider.round:before {
  border-radius: 50%;
}

.pricing-one ul.switch-toggler-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 60px;
}

.pricing-one ul.switch-toggler-list li a {
  font-size: 18px;
  font-weight: 500;
  color: #7c7d8a;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.pricing-one ul.switch-toggler-list li.active a {
  color: var(--thm-black);
}
.bt5{
  /* border: 1px solid black; */
  padding: 3%;
  border-radius: 30px;
  background-color: #897216;
  color: white;
  width: 9vw;
  z-index: 999;
  cursor: pointer;
}