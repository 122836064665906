.custom-modal{
    z-index: 9999;
    background-color: rgba(26, 24, 24, 0.801);
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    padding-top: 10%; 
}

.custom-modal-content{
    background-color: white;
    width: fit-content;
    min-width: 640px; 
    /* height: 70%;  */
    margin: auto;
    min-height: 20%;
    padding:  0 15px;
    /* border: 1px solid; */
    border-radius: 15px; 
}
.custom-modal-content-children{
    padding: 30px;
    margin: 0 20px 20px;
}
.block{
    display: block;
}
.none {
    display: none;
}

.custom-modal-content-close {
    color: #aaaaaa;
    float: right; 
    font-size: 35px;
    /* font-weight: bold; */
 }
 .custom-modal-content-close:hover, 
 .custom-modal-content-close:focus {
     text-decoration: none;
     cursor: pointer;
 }
